import './App.css';
import React, {useState, useEffect} from 'react';
import Login from './components/Login';
import { SERVER_URL, APP_NAME, VITE_VAPID_KEY, USER_TYPE_GUEST, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from './constants';
import Main from './components/Main';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import app from './resources/js/utils/initialize';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getUserTypeApiRouteRoot } from './helpers/Helpers';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BrowserChecker from './components/BrowserChecker';
import InternetChecker from './components/InternetChecker';
import DevEnvChecker from './components/DevEnvChecker';
import InstalledChecker from './components/InstalledChecker';
import "@codetrix-studio/capacitor-google-auth";
import { Plugins } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import AndroidBackButtonHandler from './components/AndroidBackButtonHandler';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AlertTitle from '@mui/material/AlertTitle';

const theme = createTheme({
    typography: {
        fontFamily: [
            "Noto Sans"
        ].join(','),
        fontSize: localStorage.getItem("fontSize") ? parseInt(localStorage.getItem("fontSize")) : 14,
    },
});

var firebaseRetryCount = 0;

function App() {

    useEffect(() => {
        checkLocalStorageToken();
    }, []);

    const setFontSize = (value) => {
        localStorage.setItem("fontSize", value.toString());
        window.location.reload();
    }

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const checkInstalled = () => {
        console.log("checkInstalled");
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            setDeferredPrompt(e);
            
            // Show your customized install prompt for your PWA
            // Your own UI doesn't have to be a single element, you
            // can have buttons in different locations, or wait to prompt
            // as part of a critical journey.
            // handleOpen();
        });
        
    }

    const [installed, setInstalled] = useState(false);

    const [declinedInstall, setDeclinedInstall] = useState(false);

    const handleInstall = () => {
        deferredPrompt.prompt();
        window.addEventListener('appinstalled', () => {
            // If visible, hide the install promotion
            // handleClose();
            // Log install to analytics
            setInstalled(true);
            console.log('INSTALL: Success');
            return true;
        });  
        //after 11 secs, if user did not install, set declined to true  
        setTimeout(() => {
            if (installed === false)
            {
                setDeclinedInstall(true);
            }
        }, 11000);
    }

    const checkLocalStorageToken = () => {
        if (localStorage.getItem("token"))
        {
            sessionStorage.setItem("token", localStorage.getItem("token"));
            setUserName(localStorage.getItem("email"));
            setFullName(localStorage.getItem("name"));
            setUserType(localStorage.getItem("type"));
            setAuth(true);
            if (localStorage.getItem('notificationsEnabled') !== null)
            {
                if (BUILD_PLATFORM === PLATFORM_PWA)
                {
                    handleFirebaseToken();
                }
                if (BUILD_PLATFORM === PLATFORM_ANDROID)
                {
                    handleFirebaseTokenAndroid();
                }
            }
        }
        checkInstalled();
    }

    const [isAuthenticated, setAuth] = useState(false);

    const [userName, setUserName] = useState("");

    const [fullName, setFullName] = useState("");

    const [userType, setUserType] = useState(0);

    const login = (user) => {
        for(var key in user) {
            if(user[key] === "") {
                setErrorMessage("E-mail and Password are required");
                handleSnackbarOpen();
                return false;
            }
        }
        fetch(SERVER_URL + 'api/login', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                const token = data.data.access_token;
                if (token) {
                    sessionStorage.setItem("token", token);
                    localStorage.setItem("token", token);
                    localStorage.setItem("email", data.data.email);
                    localStorage.setItem("name", data.data.name);
                    localStorage.setItem("type", data.data.type.toString());
                    setUserName(data.data.email);
                    setFullName(data.data.name);
                    setUserType(data.data.type);
                    checkLocalStorageToken();
                    setAuth(true);
                    return true;
                }
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                // setErrorMessage(data.message);
                // setOpen(true);
                return false;
            }
        })
        .catch(err => console.error(err))
    }

    const [guestLoggedIn, setGuestLoggedIn] = useState(false);

    const loginGuest = (user) => {
        for(var key in user) {
            if(user[key] === "") {
                setErrorMessage("Full Name and Appointment Code are required");
                handleSnackbarOpen();
                return false;
            }
            if(user[key] === false) {
                setErrorMessage("You must agree to the terms and conditions of the app");
                handleSnackbarOpen();
                return false;
            }
        }
        fetch(SERVER_URL + 'api/login-guest', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                const token = data.data.access_token;
                if (token) {
                    sessionStorage.setItem("token", token);
                    localStorage.setItem("token", token);
                    localStorage.setItem("email", data.data.email);
                    localStorage.setItem("name", data.data.name);
                    localStorage.setItem("type", data.data.type.toString());
                    localStorage.setItem("appointment_code", data.data.appointment_code.toString());
                    setUserName(data.data.email);
                    setFullName(data.data.name);
                    setUserType(data.data.type);
                    setAuth(true);
                    checkLocalStorageToken();
                    setGuestLoggedIn(true);
                    return true;
                }
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                return false;
            }
        })
        .catch(err => console.error(err))
    }

    const loginGoogle = (token) => {
        fetch(SERVER_URL + 'api/login-google', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify({"token": token})
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                const token = data.data.access_token;
                if (token) {
                    sessionStorage.setItem("token", token);
                    localStorage.setItem("token", token);
                    localStorage.setItem("email", data.data.email);
                    localStorage.setItem("name", data.data.name);
                    localStorage.setItem("type", data.data.type.toString());
                    setUserName(data.data.email);
                    setFullName(data.data.name);
                    setUserType(parseInt(data.data.type));
                    checkLocalStorageToken();
                    setAuth(true);
                }
            }  
            else {
                alert(data.message);
                // setErrorMessage(data.message);
                // setOpen(true);
            }
        })
        .catch(err => console.error(err))
    }

    async function loginGoogleAndroid() {
        const result = await Plugins.GoogleAuth.signIn();
        console.info('result', result);
        loginGoogle(result.idToken);
    };

    const handleFirebaseToken = () => {
        const messaging = getMessaging(app);
        setNotificationsFailed(false);
        getToken(messaging, {
            vapidKey: VITE_VAPID_KEY,
        })
            .then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    const token = sessionStorage.getItem("token");
                    var data = {"fcm_token": currentToken};
                    fetch(SERVER_URL + 'api/' + getUserTypeApiRouteRoot() + '/set-fcm-token',
                    {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization' : 'Bearer ' + token
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log("data", data);
                        console.log("Firebase OK");
                        setNotificationsEnabled(true);
                        localStorage.setItem("notificationsEnabled", "yes");
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                } else {
                    // Show permission request UI
                    console.log(
                        "No registration token available. Request permission to generate one."
                    );
                    // requestPermission();
                    Notification.requestPermission().then((permission) => {
                        if (permission === "granted") {
                            console.log("Notification permission granted.");
                        }
                    });
                }
            })
            .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                // retry when first attempt fails
                if (firebaseRetryCount < 5)
                {
                    firebaseRetryCount++;
                    console.log("retrying...", firebaseRetryCount);
                    handleFirebaseToken();
                }
                else
                {
                    console.log("notifications request failed", firebaseRetryCount);
                    setNotificationsFailed(true);
                }
            });

        // Foreground messages:
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            setErrorTitle(payload.notification.title);
            setErrorMessage(payload.notification.body);
            handleSnackbarOpen();
            // ...
            // const notification = new Notification(payload.data.title, {
            //     body: payload.data.body,
            //     icon: payload.data.icon,
            // });
        });
    }

    const handleFirebaseTokenAndroid = () => {
        setNotificationsFailed(false);
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                    console.log("Notifications request denied");
                    setNotificationsFailed(true);
                }
                else {
                    registerNotificationsAndroid();
                }
                });
            }
            else {
                registerNotificationsAndroid();
            }
        });
    }

    const registerNotificationsAndroid = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (fcmToken) => {
                // showToast('Push registration success');
                const token = sessionStorage.getItem("token");
                var data = {"fcm_token": fcmToken.value};
                fetch(SERVER_URL + 'api/' + getUserTypeApiRouteRoot() + '/set-fcm-token',
                {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => {
                    console.log("data", data);
                    console.log("Firebase OK");
                    setNotificationsEnabled(true);
                    localStorage.setItem("notificationsEnabled", "yes");
                })
                .catch((err) => {
                    console.error(err);
                })
            }
        );
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                console.log('Error on registration: ' + JSON.stringify(error));
                setNotificationsFailed(true);
            }
        );

        // Show us the notification payload if the app is in the foreground
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                console.log("notification received", notification);
                setErrorTitle(notification.title);
                setErrorMessage(notification.body);
                handleSnackbarOpen();
                // handleLocalNotifications(notification);
                // Plugins.LocalNotifications.schedule({
                //     id: 1,
                //     text: 'Single Local Notification',
                //     data: { secret: 'secret' }
                // });
                // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'background' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const handleLocalNotifications = (notification) => {
        LocalNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                LocalNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                    console.log("Notifications request denied");
                    // setNotificationsFailed(true);
                }
                else {
                    showLocalNotification(notification);
                }
                });
            }
            else {
                showLocalNotification(notification);
            }
        });
    }

    async function showLocalNotification(notification) {
        const result = await LocalNotifications.schedule({
            notifications: [
                {
                    title: notification.data.title,
                    body: notification.data.body,
                    id: Math.ceil(Math.random() * 100) // any random int
                }
            ]
        });
    }


    const [notificationsEnabled, setNotificationsEnabled] = useState(false);

    const [notificationsFailed, setNotificationsFailed] = useState(false);

    const requestNotifications = () => {
        console.log("requestNotifications");
        if (BUILD_PLATFORM === PLATFORM_PWA)
        {
            handleFirebaseToken();
        }
        if (BUILD_PLATFORM === PLATFORM_ANDROID)
        {
            handleFirebaseTokenAndroid();
        }
    }

    const logout = () => {
        if (localStorage.getItem("type") == USER_TYPE_GUEST)
        {
            logoutGuest();
        } 
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("type");
        localStorage.removeItem("fontSize");
        localStorage.removeItem("userDeclinedAddToHomeScreen");
        localStorage.removeItem("appointment_code");
        //localStorage.removeItem("notificationsEnabled");
        setAuth(false);

        if (BUILD_PLATFORM === PLATFORM_ANDROID)
        {
            logoutGoogleAndroid();
        }

        window.location.href = "/";
    }

    async function logoutGoogleAndroid() {
        const result = await Plugins.GoogleAuth.signOut();
        console.info('result', result);
    };

    const logoutGuest = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/logout-guest', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setGuestLoggedIn(false);
        })
        .catch((err) => {
            console.error(err);
        });
    }

    const [errorTitle, setErrorTitle] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    if (isAuthenticated)
    {
        return (
            <ThemeProvider theme={theme}>
            <React.Fragment>
                {BUILD_PLATFORM === PLATFORM_ANDROID ? <AndroidBackButtonHandler /> : null}
                <InstalledChecker 
                    declinedInstall={declinedInstall} 
                    userType={userType} 
                    installed={installed} 
                    isAuthenticated={isAuthenticated} 
                    deferredPrompt={deferredPrompt} 
                    handleInstall={handleInstall} 
                />
                {/* { BUILD_PLATFORM === PLATFORM_PWA ? <BrowserChecker /> : null } */}
                <InternetChecker />
                <BrowserRouter>
                    <Routes>
                        <Route path="" element={<Main 
                            setFontSize = {setFontSize}
                            guestLoggedIn={guestLoggedIn}
                            notificationsFailed={notificationsFailed} 
                            notificationsEnabled={notificationsEnabled} 
                            requestNotifications={requestNotifications} 
                            logout={logout} 
                            appName={APP_NAME} 
                            userName={userName} 
                            fullName={fullName} 
                            userType={userType} 
                        />} />
                        <Route path="/" element={<Main 
                            setFontSize = {setFontSize}
                            guestLoggedIn={guestLoggedIn}
                            notificationsFailed={notificationsFailed} 
                            notificationsEnabled={notificationsEnabled} 
                            requestNotifications={requestNotifications} 
                            logout={logout} 
                            appName={APP_NAME} 
                            userName={userName} 
                            fullName={fullName} 
                            userType={userType} 
                        />} />
                        <Route path="/:confirmCode" element={<Main 
                            setFontSize = {setFontSize}
                            guestLoggedIn={guestLoggedIn}
                            notificationsFailed={notificationsFailed} 
                            notificationsEnabled={notificationsEnabled} 
                            requestNotifications={requestNotifications} 
                            logout={logout} 
                            appName={APP_NAME} 
                            userName={userName} 
                            fullName={fullName} 
                            userType={userType} 
                        />} />
                    </Routes>
                </BrowserRouter>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} onClose={handleSnackbarClose}>
                    <Alert
                        icon={<NotificationsIcon fontSize="inherit" />} 
                        onClose={handleSnackbarClose}
                        severity="info"
                        variant="standard"
                        sx={{ width: '100%', whiteSpace: 'pre-wrap' }}
                    >
                        <AlertTitle>{errorTitle}</AlertTitle>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </React.Fragment>
            </ThemeProvider>
        );
    }
    else {
        return (
            <ThemeProvider theme={theme}>
            <React.Fragment>
                {BUILD_PLATFORM === PLATFORM_ANDROID ? <AndroidBackButtonHandler /> : null}
                <InstalledChecker 
                    declinedInstall={declinedInstall} 
                    userType={userType} 
                    installed={installed} 
                    isAuthenticated={isAuthenticated} 
                    deferredPrompt={deferredPrompt} 
                    handleInstall={handleInstall} 
                />
                { BUILD_PLATFORM === PLATFORM_PWA ? <DevEnvChecker /> : null }
                {/* { BUILD_PLATFORM === PLATFORM_PWA ? <BrowserChecker /> : null } */}
                <InternetChecker />
                <BrowserRouter>
                    <Routes>
                        <Route path="" element={
                            <Login appName={APP_NAME} loginGoogleAndroid={loginGoogleAndroid} loginGuest={loginGuest} loginGoogle={loginGoogle} login={login}/>
                        } />
                        <Route path="/" element={
                            <Login appName={APP_NAME} loginGoogleAndroid={loginGoogleAndroid} loginGuest={loginGuest} loginGoogle={loginGoogle} login={login}/>
                        } />
                        <Route path="/:confirmCode" element={
                            <Login appName={APP_NAME} loginGoogleAndroid={loginGoogleAndroid} loginGuest={loginGuest} loginGoogle={loginGoogle} login={login}/>
                        } />
                    </Routes>
                </BrowserRouter>
                
                <Snackbar sx={{ height: "40%" }} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {errorMessage}
                    </Alert>
                </Snackbar>
            </React.Fragment>
            </ThemeProvider>
        );
    }
}

export default App;
