import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import installqonsulta from '../installqonsulta.png';
import { SERVER_URL, USER_TYPE_GUEST } from '../constants';
import CircularProgress from '@mui/material/CircularProgress';

console.log("window.location.pathname", window.location.pathname);

function InstalledChecker (props) {

    useEffect(() => {
        if (props.isAuthenticated && props.deferredPrompt && window.location.pathname === "/" && props.userType != USER_TYPE_GUEST)
        {
            handleOpen();
        }
        else {
            handleClose();
        }
    }, [props.isAuthenticated, props.deferredPrompt]);

    useEffect(() => {
        if (props.installed)
        {
            // setTimeout(() => {
            //     setInstalling(false);
            //     setInstalled(true);
            // }, 10000);
        }
    }, [props.installed]);

    useEffect(() => {
        if (props.declinedInstall)
        {
            setInstalling(false);
            handleClose();
        }
    }, [props.declinedInstall]);

    const [installed, setInstalled] = useState(false);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [installing, setInstalling] = useState(false);

    const handleInstallButtonClick = () => {
        setInstalling(true);
        props.handleInstall();
    }

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    <b>Install Qonsulta</b>
                    </Typography>
                    
                    {installed ? 
                    null :
                    <Typography sx={{ }} gutterBottom>
                    You need to install the Qonsulta app to enable all features. Please click the Install button below.
                    </Typography>
                    }

                    <Stack direction="column" justifyContent="center" alignItems='center' sx={{ m: 0, p: 2 }} spacing={2}>
                        <img src={installqonsulta} alt="" height={180} />
                        {installed ? <Typography sx={{ }}><b>Installation Successful!</b></Typography> : null}
                        {installing ? 
                            <CircularProgress size="2rem" />
                        :
                            installed ?
                            <Button variant='outlined' size='large' onClick={handleClose}>
                                Continue
                            </Button>
                            :
                            <Button variant='outlined' size='large' onClick={handleInstallButtonClick}>
                                <InstallMobileIcon /> Install
                            </Button>
                        }
                    </Stack>                 
                </DialogContent>

                { SERVER_URL === "http://localhost:8000/" ?
                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
                : null }
            </Dialog>
        </React.Fragment>
    );
}

export default InstalledChecker;